.blocks-stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 575px), 1fr));
    align-items: flex-start;
    gap: 1rem 2rem;
}

.blocks-stats__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
}
