@use "scss/utils/_vars.scss" as *;

.collection-type-field {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border: 1px solid $box-shadow;
    border-radius: 4px;
}

.collection-type-field.is-error {
    border-color: $error;
}


.collection-type-field > legend {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.collection-type-field.is-error > legend {
    color: $error;
}

.collection-type-field__rows {
    margin-top: -1rem; // Remove the top margin from the first row and prevent jump when dragging
}

.collection-type-field__row {
    display: grid;
    align-items: flex-start;
    grid-template-columns: auto 1fr 38px;
    gap: 1rem;
    margin-top: 1rem;
}

.collection-type-field__row.no-remove {
    grid-template-columns: auto 1fr;
}

.collection-type-field__row .drag-handle {
    line-height: 40px;
    font-size: 1.5rem;
}

.collection-type-field__row .btn-danger {
    aspect-ratio: 1;
    padding: 0.5rem;
}
