@use "scss/utils/_vars.scss" as *;

.module-statistics-tools__list {
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: $pol-shadow;
}

.module-statistics-tools__item {
    white-space: nowrap;
    padding: 0.5rem;
    font-size: 0.85rem;

    &:hover {
        background: $list-hover
    }
}
