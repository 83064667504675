@use "scss/utils/_vars.scss" as *;

.products-block-stats {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 400px), 1fr));
    gap: 1rem;
}

.products-block-stats__item {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 4px;
    background-color: transparentize($secondary-color, 0.75);
    font-size: 14px;
}

.products-block-stats__loader {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    color: $main-color;
}

.products-block-stats__title {
    color: $main-color;
    padding-right: 1rem;
    line-height: 1;
}

.products-block-stats__tags {
    margin-top: 0.25rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.products-block-stats__tag {
    padding: 0.05rem 0.3rem;
    border-radius: 4px;
    background-color: darken($secondary-color, 5%);
    color: $white;
    font-size: 0.8em;
    font-weight: bold;
}

.products-block-stats__content {
    margin-top: 0.75rem;
    padding: 0.5rem;
    background: $white;
    border-radius: 3px;
}

.products-block-stats__content > table {
    border-collapse: collapse;
    width: 100%;
}

.products-block-stats__content-item {
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: transparentize($list-hover, 0.65);
    }
}

.products-block-stats__content-item__cell {
    padding: 0.2rem 0.25rem;
}

.products-block-stats__content-item__cell:is(.is-value, .is-evolution) {
    text-align: right;
    width: 0;
    white-space: nowrap;
}

.products-block-stats__content-item__cell .policon-trending-up {
    color: $lte-connect;
}

.products-block-stats__content-item__cell .policon-trending-down {
    color: $error;
}
