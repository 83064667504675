@use "scss/utils/_vars.scss" as *;

.pie-stats {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    border-radius: 4px;
}

.pie-stats--empty {
    background: transparentize($error, 0.9);
}

.pie-stats__title {
    color: $main-color;
}

.pie-stats__graph {
    flex-grow: 1;
    position: relative;
    display: grid;
    place-items: center;
    max-width: 100%;
    max-height: 100%;
}
