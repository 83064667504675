@use 'scss/utils/_vars.scss' as *;

.block-stats {
    position: relative;
    display: flex;
    flex-direction: column;
    background: $secondary-color;
    padding: 1rem;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 16px;
}

.block-stats--active {
    background: #9785f2;
}

.block-stats__loader {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    color: $main-color;
}

.block-stats__title {
    color: $main-color;
    font-size: 1.15em;
    font-weight: bold;
    padding-right: 1rem;
}

.block-stats__content {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.block-stats__value {
    color: $white;
    text-align: left;
}

.block-stats__value__current {
    font-size: 1.5em;
    font-weight: bold;
}

.block-stats__value__previous {
    white-space: nowrap;
    font-size: 0.9em;
}

.block-stats__evolution {
    min-width: 3.5rem;
    background: $white;
    border-radius: 4px;
    padding: 0.3rem;
    font-size: 0.9em;
    font-weight: bold;
    text-align: center;
}

.block-stats__evolution.positive {
    color: $lte-connect;
}

.block-stats__evolution.negative {
    color: $error;
}

.pol-popup__content .block-stats__graph {
    display: grid;
    place-items: center;
    width: 900px;
    height: 450px;
    max-width: 100%;
    max-height: 100%;
}
