.prospects-listing__stats {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(600px, 100%), 1fr));
    gap: 2rem;
}

.prospects-listing__stats .full-width {
    grid-column: 1 / -1;
}

.no-stats {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    gap: 1rem;
}
