@use "scss/utils/_vars.scss" as *;

.table-stats__tab {
    display: inline-block;
    padding: 0.5rem 1.5rem;
    border: 1px solid $secondary-color;
    border-bottom: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: $input-default-color;
    font-size: 12px;
    font-weight: bold;
}

.table-stats__content {
    box-shadow: $pol-shadow;
    border-radius: 0 4px 4px 4px;
    overflow: hidden;
}

.table-stats__table thead {
    background-color: $header;
    color: $white;
}

.table-stats__table :is(td, th) {
    border-right: 1px solid transparentize($white, 0.5);
}

.table-stats__table thead th:not(:last-child) {
    border-right: 1px solid transparentize($white, 0.5);
}


.table-stats__table tbody tr td {
    cursor: auto;
}
