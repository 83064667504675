@use "scss/utils/_vars" as *;

.prospect__tab__navigation {
    position: relative;
    list-style: none;
    padding-right: 2.5rem;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1px;
    background: $main-color;
    text-transform: uppercase;

    @media (max-width: 1440px) {
        height: unset;
    }

    @media print {
        display: none;
    }
}

.prospect__tab__navigation li:not(.tab-close) {
    display: flex;
    align-items: center;
    background: $nav-actions;
    border-left: 1px solid $white;
    color: $white;
    transition: background-color 0.2s ease-in-out;
    height: 2.5rem;
    box-shadow: 0 -1px 0 $white;

    & span {
        @media (max-width: 992px) {
            display: none;
        }
    }

    &:is(.contacts, .tab-contact):is(:hover, .active) {
        background: $menu-contacts;
    }

    &:is(.actions, .historique, .tab-task):is(:hover, .active) {
        background: $menu-actions;
    }

    &:is(.devis-offres, .affaires, .tab-quote):is(:hover, .active) {
        background: $menu-devis;
    }

    &:is(.historique-mails, .received-mails):is(:hover, .active) {
        background: $menu-mails;
    }

    &.stats-360:is(:hover, .active) {
        background: $nav-actions-active;
    }
}

.prospect__tab__navigation li.group {
    position: relative;
}

.prospect__tab__navigation li.group.active {
    background: lighten($main-color, 25%)
}

.prospect__tab__navigation li.group .group-label {
    padding: 0.75rem;
    font-size: 0.75rem;
    cursor: default;
}

.prospect__tab__navigation li.group .sub-menu {
    position: absolute;
    z-index: 3;
    top: 100%;
    left: 0;
    min-width: 100%;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.2s, opacity 0.2s;
}

.prospect__tab__navigation li.group:hover .sub-menu {
    visibility: visible;
    opacity: 1;
}

.prospect__tab__navigation li.group .sub-menu li {
    border-right: 1px solid $white;
}

.prospect__tab__navigation li a {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.75rem;
    font-size: 0.75rem;
}

.prospect__tab__navigation li i {
    font-size: 1rem;
}

.prospect__tab__navigation .tab-close {
    padding: 0.75rem;
    position: absolute;
    top: calc(2.5rem / 2);
    right: 0;
    transform: translateY(-50%);
    color: $white;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.prospect__tab__navigation .tab-close i {
    font-size: 1.3rem;
}
