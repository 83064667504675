.prospect-tab__actions__360-view,
.prospect-tab__actions__360-view__form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
}

.prospect-tab__actions__360-view__form .input-field-wrapper {
    margin-bottom: unset;
    min-width: 150px;
}
